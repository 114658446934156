import React from 'react'
import './Tag.css'

export const Tag = ({ background = 'white', color = 'black', content, icon, angle, left, right, top, bottom, border, fontSize = '16px', position = 'absolute', largeIcon }) => (
  <div
    style={{
      position,
      background,
      color,
      transform: `rotate(${angle}deg)`,
      left,
      right,
      top,
      bottom,
      border,
      fontSize
    }}
    className={`tag ${icon || 'no-icon'}${largeIcon ? ' large-icon' : ''}`}
  >
    {content}
  </div>
)