import React, { useRef } from 'react'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { useGSAP } from '@gsap/react'
import './Reveal.scss'

export const Reveal = ({ pin = true, zIndex, background, children }) => {
  const root = useRef()
  const inner = useRef()

  useGSAP(() => {
    gsap.matchMedia().add('(min-width: 1001px)', () => {
      if(pin) {
        ScrollTrigger.create({
          trigger: root.current,
          pin: inner.current,
          pinSpacing: false,
          start: 'top bottom',
          end: 'bottom top'
        })
      } else {
        ScrollTrigger.create({
          trigger: inner.current,
          pin: inner.current,
          start: 'top top',
          end: () => '+=' + (window.innerHeight + 1000)
        })
      }
    })
  })

  return (
    <div className={`reveal screen${pin ? ' pin' : ''}`} ref={root} style={{ zIndex, background }}>
      <div ref={inner} className={pin ? 'pin' : ''}>
        {children}
      </div>
    </div>
  )
}