export const faqs = [
  {
    q: `Do I have to be accredited to run your games?`,
    a: `Yes, our games deliver customised learnings with valuable insights for real world application. To make sure you're set up to run the games smoothly and get the most out of the debrief options, we want to ensure you have everything you need to deliver a great end-to-end experience. However, if you want an expert facilitation for a one-off event - we've got you covered! Sit back, relax, and let one of our facilitators guide the experience.`
  },
  {
    q: `How do I know which game is right for me?`,
    a: `Feeling overwhelmed with choice? No sweat! We're here to be your guide. Just tell us a bit about yourself and your event goals (think "team bonding" or "leadership training"), and we'll match you with the perfect game. You can reach our friendly team here!`
  },
  {
    q: `Can your games be run virtually?`,
    a: `Global team? No problem! Engage your geographically scattered crew with a virtual experience that'll blow their minds (and socks potentially!). Most of our games are hybrid powerhouses, designed to be played both in-person and in virtual settings. Ready to find the perfect game for your team? Contact us today!`
  },
  {
    q: `How many participants can be in a game?`,
    a: `How long is a piece of string? Our games are built for flexibility, scaling from intimate gatherings of 6-8 to epic clashes with thousands. Have a unique vision in mind? We're the customisation champions! Let's brainstorm and make your team-building dream a reality.`
  },
  {
    q: `How much does it cost?`,
    a: `There are a few pricing things to consider depending on your unique circumstance. Visit our pricing page for more info or feel free to chat to us directly.`
  }
]