import React from 'react'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { useGSAP } from '@gsap/react'
import { assetUrl } from '../../utilities'
import './Vectors.scss'

export const Vectors = ({ children }) => {
  useGSAP(() => {
    gsap.to('.vectors .blue', {
      keyframes: {
        x: ['60%', '30%', '40%', '65%', '60%'],
        y: ['-20%', '-20%', '-10%', 0, '-20%']
      },
      duration: 20,
      repeat: -1
    })
    gsap.to('.vectors .yellow', {
      keyframes: {
        x: ['-50%', '-20%', '-20%', '-80%', '-50%'],
        y: ['40%', '40%', '30%', '20%', '40%']
      },
      duration: 20,
      repeat: -1
    })
    ScrollTrigger.create({
      trigger: '.vectors-wrapper',
      pin: '.vectors',
      start: 'top bottom'
    })
  })

  return (
    <div className="vectors-wrapper">
      <div className="vectors">
        <img className="blue" src={assetUrl('blue-vector.png')} alt="blue vector" />
        <img className="yellow" src={assetUrl('yellow-vector.png')} alt="yellow vector" />
      </div>
      {children}
    </div>
  )
}