export const assetUrl = asset => `https://www.podandhive.com/assets/marketing/${asset}`
export const createUid = (start = 1) => (value => () => ++value)(start - 1)

export const post = (path, body) => {
  const host = window.location.hostname.includes('localhost') ? 'http://localhost:4000/'
    : window.location.hostname.includes('staging') ? 'https://ap.staging.podandhive.com:4000/'
      : window.location.hostname.includes('preview') ? 'https://preview.podandhive.com:4000/'
        : 'https://ap.podandhive.com:4000/'

  return fetch(
    host + path,
    {
      method: 'POST',
      body: JSON.stringify(body),
      headers: { 'Content-Type': 'application/json' }
    }
  )
}
