import React from 'react'
import { Navigation, NavigationMenu } from '.'
import { BookDemoButton, Logo } from '../widgets'
import './Header.scss'

export const Header = () => {
  return (
    <header>
      <Logo />
      <NavigationMenu />
      <Navigation />

      <div style={{ display: 'flex', gap: 8 }}>
        <BookDemoButton />
        <button
          className="login aqua"
          onClick={() => window.location.href = '/portal'}
        >
        </button>
      </div>
    </header>
  )
}