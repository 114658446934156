exports.components = {
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-accreditation-js": () => import("./../../../src/pages/accreditation.js" /* webpackChunkName: "component---src-pages-accreditation-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-games-critters-js": () => import("./../../../src/pages/games/critters.js" /* webpackChunkName: "component---src-pages-games-critters-js" */),
  "component---src-pages-games-dragons-js": () => import("./../../../src/pages/games/dragons.js" /* webpackChunkName: "component---src-pages-games-dragons-js" */),
  "component---src-pages-games-first-responder-js": () => import("./../../../src/pages/games/first-responder.js" /* webpackChunkName: "component---src-pages-games-first-responder-js" */),
  "component---src-pages-games-grimsby-js": () => import("./../../../src/pages/games/grimsby.js" /* webpackChunkName: "component---src-pages-games-grimsby-js" */),
  "component---src-pages-how-it-works-js": () => import("./../../../src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */)
}

