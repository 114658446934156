import React from 'react'
import './Logo.scss'

export const Logo = ({ fullWidth }) => (
  <svg width={fullWidth && '100%'} height={fullWidth ? undefined : 16} viewBox="0 0 1440 182" fill="none" xmlns="http://www.w3.org/2000/svg" className="logo">
    <g clipPath="url(#clip0_402_1699)">
      <path d="M23.6628 104.15V183.449H-3.5V4.38867H68.5117C77.7602 4.38867 85.4885 5.41419 91.747 7.46522C98.0056 9.51625 103.479 12.402 108.192 16.1702C113.994 20.7731 118.555 26.3538 121.925 32.9123C125.27 39.4709 126.942 46.6018 126.942 54.2812C126.942 61.9607 125.27 69.0677 121.925 75.6263C118.58 82.1848 113.994 87.7655 108.192 92.3684C103.479 96.1128 98.0056 99.0224 91.747 101.073C85.4885 103.124 77.7602 104.15 68.5117 104.15H23.6628ZM67.1435 79.8476C73.6554 79.8476 78.8245 79.1321 82.6252 77.6773C86.426 76.2225 89.5933 74.3146 92.1271 71.9296C94.4836 69.5447 96.3333 66.8498 97.7016 63.8686C99.0698 60.8875 99.7286 57.6917 99.7286 54.2812C99.7286 50.8708 99.0445 47.675 97.7016 44.6938C96.3333 41.7127 94.4836 39.0177 92.1271 36.6328C89.5933 34.2479 86.426 32.34 82.6252 30.8851C78.8245 29.4303 73.6554 28.7149 67.1435 28.7149H23.6628V79.8714H67.1435V79.8476Z" />
      <path d="M370.14 183.425V4.38867H427.202C442.431 4.38867 455.15 5.84347 465.387 8.72923C475.624 11.6388 484.644 15.8124 492.423 21.2501C504.206 29.4303 513.479 39.8286 520.27 52.4448C527.061 65.0611 530.456 78.8698 530.456 93.8709C530.456 108.872 527.112 122.681 520.397 135.297C513.682 147.913 504.459 158.312 492.677 166.492C484.898 171.953 475.827 176.127 465.514 179.013C455.176 181.922 442.405 183.353 427.202 183.353H370.14V183.425ZM503.293 93.8948C503.293 83.8304 501.266 74.4577 497.187 65.7527C493.107 57.0477 487.457 49.726 480.21 43.7637C474.23 38.8269 467.034 35.0588 458.596 32.5069C450.184 29.955 439.263 28.6672 425.859 28.6672H397.328V159.099H425.859C439.263 159.099 450.184 157.811 458.596 155.259C467.009 152.707 474.23 148.963 480.21 144.002C487.457 138.04 493.107 130.694 497.187 122.013C501.266 113.308 503.293 103.935 503.293 93.8709V93.8948Z" />
      <path d="M1006.01 99.0224H900.017V183.425H872.854V4.38867H900.017V74.72H1006.01V4.38867H1033.17V183.425H1006.01V99.0224Z" />
      <path d="M1082.1 4.38867H1109.26V183.425H1082.1V4.38867Z" />
      <path d="M1421.76 74.72V99.0224H1359.25V159.122H1443.5V183.425H1332.09V4.38867H1440.79V28.691H1359.28V74.72H1421.78H1421.76Z" />
      <path d="M160.211 159.408V26.568H188.438V159.408H160.211ZM188.438 26.568V0H298.458V26.568H188.438ZM188.438 185.976V159.408H298.458V185.976H188.438ZM298.458 159.408V26.568H326.685V159.408H298.458Z" />
      <path d="M1150.21 106.702V4.38867H1177.37V106.702H1150.21ZM1177.37 157.858V106.702H1204.53V157.858H1177.37ZM1204.53 183.425V157.858H1236.76V183.425H1204.53ZM1236.76 157.858V106.702H1263.93V157.858H1236.76ZM1263.93 106.702V4.38867H1291.09V106.702H1263.93Z" />
      <path d="M668.297 57.3096C665.282 53.6368 664.217 49.8686 665.003 45.7427C665.788 41.6645 667.892 38.0633 671.287 35.0344C674.708 31.9817 678.584 30.4077 683.145 30.2407C689.784 30.0738 694.978 31.3139 698.627 33.9374C702.276 36.5608 705.367 41.2114 707.8 47.7938L707.876 48.0084L733.518 39.375L733.417 39.1603C728.83 27.8797 722.749 19.3655 715.325 13.8802C707.901 8.39485 697.056 5.60449 683.12 5.60449C674.277 5.60449 666.219 7.63168 659.15 11.6145C652.08 15.5973 646.506 20.868 642.502 27.2834C638.524 33.675 636.472 40.6867 636.371 48.0799C636.269 55.497 638.676 62.7234 643.491 69.5919L652.79 81.0157L651.574 81.7073C639.817 88.3136 630.999 95.7307 625.424 103.768C619.597 112.163 616.505 120.748 616.252 129.31C615.998 137.872 617.949 146.005 622.105 153.47C626.26 160.934 632.24 167.421 639.918 172.74C647.595 178.058 656.54 181.707 666.498 183.615C676.431 185.523 687.301 185.094 698.526 182.375C709.447 179.728 720.494 174.242 731.415 166.086L732.327 165.394L750.241 183.496H785.335L668.221 57.3096H668.297ZM713.906 148.366C705.798 153.613 697.791 156.975 690.113 158.359C686.642 159.003 683.297 159.313 680.079 159.313C675.822 159.313 671.819 158.74 668.12 157.619C661.658 155.64 656.312 152.444 652.207 148.08C648.102 143.739 645.695 138.635 644.986 132.888C644.276 127.14 645.746 121.106 649.319 114.953C652.739 109.062 658.719 103.315 667.055 97.9248L667.993 97.3286L715.072 147.579L713.906 148.342V148.366Z" />
      <path d="M769.017 108.705H740.79V135.273H769.017V108.705Z" />
    </g>
    <defs>
      <clipPath id="clip0_402_1699">
        <rect width="1447" height="186"  transform="translate(-3.5)"/>
      </clipPath>
    </defs>
  </svg>
)