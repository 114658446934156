import React, { useRef } from 'react'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { useGSAP } from '@gsap/react'
import './FadeIn.scss'

export const FadeIn = ({ children }) => {
  const ref = useRef()

  useGSAP(() => {
    gsap.matchMedia().add('(min-width: 1001px)', () => {
      const pin = ScrollTrigger.create({
        trigger: ref.current,
        start: 'bottom bottom',
        end: () => '+=' + (window.innerHeight + 1000),
        pin: true
      })

      gsap.to(ref.current, {
        scrollTrigger: {
          trigger: ref.current,
          start: pin.start + 1000,
          toggleActions: 'play none none reverse'
        },
        opacity: 1
      })
    })
  })

  return (
    <div className="fade-in" ref={ref}>
      {children}
    </div>
  )
}