import React, { useEffect, useRef, useState } from 'react'
import { ChevronDown } from '../widgets'
import { faqs } from '../../faqs'
import './FaqSection.scss'

export const FaqSection = () => {
  return (
    <section className="faq">
      <h1>FAQs</h1>
      <ul>
        {faqs.map((faq, i) => <Faq key={i} {...faq} />)}
      </ul>
    </section>
  )
}

const Faq = ({ q, a }) => {
  const [expanded, setExpanded] = useState(false)
  const [answerHeight, setAnswerHeight] = useState(0)
  const answer = useRef()

  useEffect(() => {
    if(answer.current) {
      setAnswerHeight(answer.current.clientHeight)
    }
  }, [answer])

  return (
    <li className={expanded ? 'expanded' : undefined}>
      <div onClick={() => setExpanded(!expanded)} className="q">
        <h3>{q}</h3>
        <div><ChevronDown /></div>
      </div>
      <div className="a" style={{ height: expanded ? answerHeight : 0 }}>
        <h5 ref={answer}>{a}</h5>
      </div>
    </li>
  )
}