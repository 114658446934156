const games = [
  { slug: '/games/dragons/', text: 'The Dragons are Coming' },
  { slug: '/games/critters/', text: 'Woodland Critters' },
  { slug: '/games/grimsby/', text: "Grimsby's Code" },
  { slug: '/games/first-responder/', text: 'First Responder' }
]

export const routes = [
  { slug: '/', text: 'Home' },
  { slug: '/how-it-works/', text: 'How it works' },
  { slug: '/accreditation/', text: 'Accreditation' },
  { slug: '/pricing/', text: 'Pricing' },
  { slug: '/games/', text: 'Games', subOptions: games },
  { slug: '/about-us/', text: 'About' },
  { slug: '/contact-us/', text: 'Contact' }
]