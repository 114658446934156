import React, { useEffect, useState } from 'react'
import gsap from 'gsap'
import ScrollSmoother from 'gsap/ScrollSmoother'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { useGSAP } from '@gsap/react'

gsap.registerPlugin(useGSAP)
gsap.registerPlugin(ScrollSmoother)
gsap.registerPlugin(ScrollTrigger)

if(typeof window === 'object' && window.localStorage.getItem('socket.authenticatedUser')) {
  document.body.classList.add('authenticated')
}

export const Layout = ({ className, children }) => {
  const [active, setActive] = useState(false)

  useEffect(() => {
    setTimeout(() => document.querySelectorAll('header > nav,.logo,.navigation-menu > button').forEach(element => element.classList.remove('inverse')))
    setActive(true)
  }, [])

  useGSAP(() => {
    gsap.matchMedia().add('(pointer:fine)', () => {
      ScrollSmoother.create({
        smooth: 1,
        wrapper: '#smooth-wrapper'
      })
    })

    setTimeout(() => ScrollTrigger.refresh(), 500)
  })

  return (
    <article className={`${className}${active ? ' active' : ''}`}>
      {children}
    </article>
  )
}

export const PageHead = ({ title }) => <>
  <title>Pod&Hive{title ? ' - ' + title : ''}</title>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
  <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap" rel="stylesheet" />
</>