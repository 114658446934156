import React, { useRef } from 'react'
import gsap from 'gsap'
import { useGSAP } from '@gsap/react'
import { assetUrl } from '../../utilities'
import './Team.scss'

export const Team = ({ groupTitle, people }) => <section className="team-section">
  <div>
    {groupTitle && <h3>{groupTitle}</h3>}
    <div className="team">
      {people.map((person, index) => (
        <TeamMember person={person} key={index}/>
      ))}
    </div>
  </div>
</section>

const TeamMember = ({ person: { name, title, description, linkedInURL } }) => {
  const teamMemberRef = useRef()

  useGSAP(() => {
    gsap.to(teamMemberRef.current, {
      scrollTrigger: {
        trigger: teamMemberRef.current,
        start: 'center bottom',
        end: 'bottom center',
        toggleActions: 'play none none reverse',
        scrub: true
      },
      duration: 0.6,
      opacity: 1
    })
  })

  return <div className="team-member" ref={teamMemberRef}>
    <div className="mugshot">
      <img src={assetUrl(`team/${name.replace(" ", "_")}.jpg`)} alt={'Picture of ' + name}/>
    </div>
    <div>
      <h3>{name}</h3>
      <h4>{title} <span>/ {description}</span></h4>
    </div>
    <h5><a href={linkedInURL}>LinkedIn</a></h5>
  </div>
}
