import React, { Fragment, useRef } from 'react'
import gsap from 'gsap'
import { useGSAP } from '@gsap/react'
import './TitleSection.scss'

export const TitleSection = ({ background, color, small, children, pin = true, exitLeft = true }) => {
  const root = useRef()
  const content = useRef()
  const marker = useRef()

  useGSAP(() => {
    gsap.from(content.current.querySelectorAll('div'), {
      scrollTrigger: {
        trigger: root.current,
        start: 'center bottom',
        end: () => '+=' + window.innerHeight * 0.5,
        scrub: true,
        ease: 'linear'
      },
      height: 0
    })
    if(pin) {
      gsap.to(root.current, {
        scrollTrigger: {
          trigger: root.current,
          start: 'center center',
          end: () => '+=' + (window.innerWidth * 2.6),
          pin: true,
          pinSpacing: false,
          anticipatePin: 1,
          ease: 'linear'
        }
      })
    }
    if(exitLeft) {
      gsap.to(content.current, {
        scrollTrigger: {
          trigger: marker.current,
          start: 'top top',
          end: () => '+=' + window.innerHeight,
          scrub: true,
          ease: 'linear'
        },
        x: () => -window.innerWidth
      })
    }

    const fadeInElements = content.current.querySelectorAll('button,.fade')
    if(fadeInElements.length > 0) {
      gsap.from(fadeInElements, {
        scrollTrigger: {
          trigger: marker.current,
          start: 'center center',
          toggleActions: 'play none none reverse'
        },
        opacity: 0,
        duration: 0.6,
        stagger: 1
      })
    }
  })

  const render = () => (
    [children].flat().map(child =>
      typeof child === 'string'
        ? child.split(' ').map((word, i) => <Fragment key={i}>
            <div>
              <span>{word}</span>
            </div>
            <span> </span>
          </Fragment>)
        : child
    )
  )

  return (
    <section
      className="title screen"
      style={{ background, color, marginBottom: exitLeft ? '30lvh' : 0 }}
      ref={root}
    >
      {small
        ? <h2 ref={content}>{render()}</h2>
        : <h1 ref={content}>{render()}</h1>
      }
      <div ref={marker}></div>
    </section>
  )
}
