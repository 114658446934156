import React from 'react'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { CalendarProvider, ContactProvider, Header } from './src/components'

export function shouldUpdateScroll() {
  window.scrollTo(0, 0)
  ScrollTrigger.refresh()
  return false
}

export function wrapPageElement({ element }) {
  return (
    <CalendarProvider>
      <ContactProvider>
        <Header />
        {element}
      </ContactProvider>
    </CalendarProvider>
  )
}