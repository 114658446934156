import React, { createContext, useContext, useState } from 'react'
import { InlineWidget } from 'react-calendly'
import { Dialog } from '../layout'

const context = createContext({})

export const useCalendar = () => useContext(context)

export const CalendarProvider = ({ children }) => {
  const [open, setOpen] = useState(false)
  const openCalendar = () => setOpen(true)

  return (
    <context.Provider value={{ openCalendar }}>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <InlineWidget url="https://calendly.com/dale-podandhive/30min?hide_event_type_details=1&hide_gdpr_banner=1&primary_color=34bfd2" />
      </Dialog>
      {children}
    </context.Provider>
  )
}
