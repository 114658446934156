import React from 'react'
import './CircleText.css'

if(typeof window !== 'undefined') {
  const font = new FontFace('pixelmix', `url(https://podandhive.s3.ap-southeast-2.amazonaws.com/assets/pixelmix.woff)`)
  document.fonts.add(font)
  font.load()
}


export const CircleText = ({ text, centerText, count }) => {
  const displayText = text + '  '
  const angle = 360 / (displayText.length * count)

  return (
    <div className="circle-text">
      <div className="lettering">
        {Array(count).fill().map((_, i) =>
          displayText.split('').map((letter, j) =>
            <span
              key={j}
              style={{ transform: `rotate(${(360 / count * i) + (angle * j)}deg)` }}
            >
              {letter}
            </span>
          )
        )}
      </div>
      <div className="center">
        {centerText}
      </div>
    </div>
  )
}