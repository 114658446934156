import React from 'react'

export const ChevronDown = ({ size = 20 }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 7.5L10 12.5L15 7.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export const Menu = () => (
  <svg width="36" height="36" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.5 18H3.5V16H15.5V18ZM21.5 13H3.5V11H21.5V13ZM15.5 8H3.5V6H15.5V8Z" fill="white" />
  </svg>
)

export const Close = () => (
  <svg width={24} height={24} viewBox="0 0 256 256" enableBackground="new 0 0 256 256" xmlns="http://www.w3.org/2000/svg">
    <g><path fill="#000000" d="M161.4,128l77.7-77.7c9.2-9.2,9.2-24.2,0-33.4s-24.2-9.2-33.4,0L128,94.6L50.3,16.9c-9.2-9.2-24.2-9.2-33.4,0s-9.2,24.2,0,33.4L94.6,128l-77.7,77.7c-9.2,9.2-9.2,24.2,0,33.4c4.6,4.6,10.6,6.9,16.7,6.9c6,0,12.1-2.3,16.7-6.9l77.7-77.7l77.7,77.7c4.6,4.6,10.6,6.9,16.7,6.9c6,0,12.1-2.3,16.7-6.9c9.2-9.2,9.2-24.2,0-33.4L161.4,128z"/></g>
  </svg>
)

export const Back = () => (
  <svg width="47" height="46" viewBox="0 0 47 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.43934 10.3663C-0.146447 10.9521 -0.146447 11.9019 0.43934 12.4877L9.98528 22.0336C10.5711 22.6194 11.5208 22.6194 12.1066 22.0336C12.6924 21.4478 12.6924 20.4981 12.1066 19.9123L3.62132 11.427L12.1066 2.94172C12.6924 2.35593 12.6924 1.40619 12.1066 0.8204C11.5208 0.234613 10.5711 0.234613 9.98528 0.8204L0.43934 10.3663ZM1.5 12.927H46.5V9.927H1.5V12.927Z" fill="black" />
  </svg>
)