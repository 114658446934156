import React from 'react'
import { useCalendar } from './Calendar'

export const BookDemoButton = ({ className, content = 'Book a demo' }) => {
  const { openCalendar } = useCalendar()

  return (
    <button className={`book-demo${className ? ` ${className}` : ''}`} onClick={openCalendar}>
      {content}
    </button>
  )
}