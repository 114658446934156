import React from 'react'

export const games = {
  dragons: {
    name: `The Dragons are Coming`,
    participants: `6-600`,
    duration: `1.5 - 2.5hrs`,
    mode: `Hybrid`,

    tagline: <>Rise together or<br/>fall trying</>,
    blurb: [
      `The Dragons are circling, and the only way to save the kingdom (and your precious troops) is to work together. But will sneaky scoundrels put personal gain over the greater good?`,
      `Get ready for an exhilarating battle of trust and betrayal in this fast-paced simulation based on the "Tragedy of the Commons." Will you rise as a champion of cooperation, or will greed lead to a fiery demise?`
    ],
    overview: [
      `You'll be placed in teams, each with a personal mission alongside a shared objective.`,
      `Each round you'll strategise like military masterminds, debate with a fiery passion, and influence other teams with the charm of a used car salesman. The tension builds as the game reaches its climax - will your team achieve the ultimate goal and bask in the glory of victory, or will your carefully laid plans crumble, leaving you with... well, nothing but bragging rights for the team who got closest?`,
      `This is fun, fiercely interactive, and guaranteed to leave a lasting impression. It'll be the talk of your next conference, offsite, or training program. You'll walk away with valuable lessons learned (and maybe a newfound respect for teamwork... or a diabolical plan for world domination, we won't judge).`
    ],
    outcomesSummary: `This simulation will show you the power (and pitfalls) of group dynamics. Get ready to conquer challenges like:`,
    outcomes: [
      { title: `Taming your organisation's or team's "Dragons"`, content: `Whether you're struggling with a tough market, aggressive competitors, a difficult regulator, scarce resources or something even more fiendish, this game labels your biggest challenge as your "Dragon", and shows you how you can cooperate to defeat it.` },
      { title: `Trust without seeing`, content: `How do you hold others accountable when they're shrouded in mystery? Develop your trust radar (with a healthy dose of skepticism!).` },
      { title: `The disruption dance`, content: `Can you adapt your strategy on the fly when chaos throws a wrench in the plan?` },
      { title: `Herd mentality mayhem`, content: `This simulation will show you the power of group dynamics. Learn to leverage the pack, but avoid the stampede!` }
    ],
    addendum: `But the adventure doesn't end there! The Dragons Are Coming offers a customisable debrief to turn these lessons into lasting victories for your team by helping you dissect the experience and transform it into actionable takeaways.`
  },
  'first-responder': {
    name: `First Responder`,
    participants: `8-300`,
    duration: `1.5 - 3hrs`,
    mode: `Hybrid`,

    tagline: <>United minds,<br/>unstoppable results</>,
    blurb: [
      `Code Red! A biological war is upon us! A new wave of viral threats sweeps across our planet, but so does the unwavering spirit of cooperation. Together, we can defeat this invisible enemy and secure a healthy future for humanity. But remember, this fight isn't just about our own backyard –  we must stop this pandemic in its tracks, everywhere! Will you be our ‘First Responder’?`,
    ],
    overview: [
      `Ready to rewrite the ending to the pandemic story? ‘First Responder’ throws you headfirst into a global health crisis, but this time, you're in charge!`,
      `Easy to grasp, brutal to master: This simulation is a heart-pounding test test of time, resource and relationship management.. Teams must conquer a whirlwind of challenges, think on their feet, strategise like champions, and adapt to the unexpected. `
    ],
    outcomesSummary: `First Responder's flexible debrief isn't your average post-game wrap-up. It's a tailor-made learning experience designed to tackle your specific needs. Here's a taste of what you can explore:`,
    outcomes: [
      { title: `THE URGENT VS THE IMPORTANT`, content: `Dive deep into the struggle between immediate needs and long-term goals.` },
      { title: `LOCAL NEEDS VS GLOBAL DEMANDS`, content: `Manage your resources wisely while facing pressure from neighbouring teams. Can you balance your own city's needs with the broader fight?` },
      { title: `ADAPTING TO CHAOS`, content: `The unexpected is inevitable! Learn to react and improvise when your plans are thrown off track.` },
      { title: `REAL-LIFE RESPONSE TO VOLATILITY`, content: `The simulation mirrors the unpredictable nature of a real crisis. Hone your ability to respond and adapt in the moment.` },
      { title: `CROSS-TEAM COLLABORATION`, content: `Influence other teams and strategies together to optimise resource allocation across the regions.` },
    ],
    addendum: `Can you contain the outbreak and save humanity?`
  },
  grimsby: {
    name: `Grimsby's Code`,
    participants: `6-60+`,
    duration: `1.5 - 2.5hrs`,
    mode: `In person`,

    tagline: <>RESULTS MATTER,<br/>REPUTATION EVEN MORE</>,
    blurb: [
      `Ahoy, mateys! Calling all captains with a thirst for adventure and a knack for getting the job done!`,
      `The legendary Berlugian Sea, teeming with fish, is finally opening its coveted waters for a fishing frenzy! But here's the catch (pun intended): not only do you need to land the biggest catch, you also need the other teams' dubious seal of approval to survive these treacherous waters.`,
      `Get ready to test your skills and haul in more fish than Davy Jones' locker! Can you become the ultimate champion, and live by the code? `,
    ],
    overview: [
      `This immersive simulation throws you headfirst into the turbulent waters of team dynamics. Brace yourself for an information avalanche – can you make sense of the chaos faster than a social media scroll? Navigate inter-team rivalries and intra-team squabbles to find common ground, all while making decisions that won't rub people the wrong way. Remember, every action has a consequence, matey! Be prepared to own your choices and lead your team to success.`,
      `But Grimsby's Code isn't just about survival – it's about thriving. Through a rigorous debrief, you'll explore the age-old tension between Agency (getting things done) and Communion (getting along with others). Can you strike the perfect balance and become the ultimate team captain? Get hooked on the challenge, and unlock the secrets to success with Grimsby's Code!`,
    ],
    outcomesSummary: `Grimsby's Code isn't just a simulation, it's a hilarious learning lab! Get ready to dissect the drama and emerge with these valuable takeaways:`,
    outcomes: [
      { title: `THE TIGHT ROPE WALK`, content: `Can you balance crushing results with a squeaky-clean code of conduct?` },
      { title: `TARGETS VS TIME`, content: `Tick-tock! Balancing ambitious targets with tight deadlines is a real juggling act. Grimsby's Code will put your time management skills to the test.` },
      { title: `PLAN B IS YOUR BFF`, content: `The best plans often go awry. This simulation will show you the importance of adapting on the fly when faced with unexpected complexities.` },
      { title: `LEARNING WITH LAUGHTER`, content: `Who says learning can't be fun? Grimsby's Code is a hilarious way to gain valuable insights into the challenges and triumphs of teamwork.` },
    ],
    addendum: `Plus, Grimsby's Code offers a customisable debrief tailored to your specific needs. We'll help you transform these lessons into actionable takeaways for lasting success!`
  },
  critters: {
    name: `Woodland Critters`,
    participants: `8-200+`,
    duration: `1.5 - 3hrs`,
    mode: `In person`,

    tagline: <>Diversity, Commerciality,<br/>Talent Management: Done Different</>,
    blurb: [
      `In the woodlands, far from prying eyes, a Critter Community has thrived for hundreds of years. Critters work together to ensure that there is enough for all the creatures of the woodlands. Once in a generation a new clan must be chosen to watch over the Woodland for the decades to come.`,
      `Get ready to laugh, learn, and win as you discover the superpower of a truly diverse crew!`,
    ],
    overview: [
      `But there's more: this is a simulation, and so you don't just do DEI without context.  To win you will have to manage your commercials, make sure your books are balanced, and also learn about attracting, developing and ultimately keeping your team happy!`,
      `So ditch the corporate slides, and embrace this new adventure! This simulation is your key to a fun and unforgettable conversation about DEI, about commercial acumen, about talent development, or all three.`,
    ],
    outcomesSummary: `Unlock the secrets of belonging and skyrocket performance with this customisable game to suit your companies needs. Woodland Critters tackles real-world challenges like:`,
    outcomes: [
      { title: `BUILDING A DIVERSE TEAM`, content: `Mix it up, make it magic! Discover the power of diversity and create a team that's stronger together.` },
      { title: `THRIVING IN THE CHAOS`, content: `Juggling teamwork and feeling overwhelmed? Discover how to thrive, not just survive under pressure.` },
      { title: `MANAGING YOUR TALENT`, content: `Deal with the challenges of attracting, retaining, developing your people and keeping them happy and engaged!` },
      { title: `BOSS UP YOUR SMALL BUSINESS`, content: `You can't win if you're not managing your clan's economy well. Build your production, manage expenses and use your surplus wisely to be able to do well in the talent market.` },
    ],
    addendum: `Ready for a memorable and impactful DEI experience? Ditch the dull, embrace Woodland Critters`
  },
}