import React, { useState } from 'react'
import { Link } from 'gatsby'
import { useLocation } from '@reach/router'
import { Back, Close, Menu } from '../widgets'
import { routes } from '../../routes'
import './NavigationMenu.scss'

export const NavigationMenu = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [selectedRoute, setSelectedRoute] = useState()
  const [submenuOpen, setSubmenuOpen] = useState(false)
  const { pathname } = useLocation()
  const openStyle = { height: '100dvh', paddingTop: 40, paddingBottom: 40 }

  const handleMenuClick = route => {
    if(route.subOptions) {
      setSelectedRoute(route)
      setSubmenuOpen(true)
    } else {
      setMenuOpen(false)
    }
  }

  return <div className="navigation-menu">
    <button onClick={() => setMenuOpen(true)}>
      <Menu />
    </button>
    <div
      className="menu"
      style={(menuOpen && !submenuOpen) ? openStyle : undefined}
    >
      <div onClick={() => setMenuOpen(false)}>
        <Close />
      </div>
      {routes.map(route => {
        const { slug, text, subOptions } = route
        return (
          <div
            key={slug}
            className={(pathname === slug || subOptions?.some?.(item => item.slug === pathname)) ? 'active' : ''}
            onClick={() => handleMenuClick(route)}
          >
            <h5>
              {subOptions ? text : <Link to={slug}>{text}</Link>}
            </h5>
          </div>
        )
      })}
    </div>
    <div
      className="submenu"
      style={submenuOpen ? openStyle : undefined}
    >
      <div onClick={() => setSubmenuOpen(false)}>
        <Back />
      </div>
      {(selectedRoute?.subOptions || []).map(({ slug, text }) =>
        <div
          key={slug}
          className={pathname === slug ? 'active' : ''}
          onClick={() => {
            setSubmenuOpen(false)
            setMenuOpen(false)
          }}
        >
          <h5>
            <Link to={slug}>{text}</Link>
          </h5>
        </div>
      )}
    </div>
  </div>
}
