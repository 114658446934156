import React from 'react'
import { Link } from 'gatsby'
import { useLocation } from '@reach/router'
import { routes } from '../../routes'
import './Navigation.scss'

export const Navigation = () => {
  const { pathname } = useLocation()

  return (
    <nav>
      {routes.map(item => {
        const { slug, text, subOptions } = item

        return <div
          key={slug}
          className={(pathname === slug || subOptions?.some?.(item => item.slug === pathname)) ? 'active' : ''}
        >
          <h5>
            {subOptions ? text : <Link to={slug}>{text}</Link>}
          </h5>

          {subOptions &&
            <div className="links-dropdown">
              {subOptions.map(({ slug, text }) =>
                <Link key={slug} to={slug}>{text}</Link>
              )}
            </div>
          }
        </div>
      })}
    </nav>
  )
}